import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles3 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { FreelanceWork3 } from "../../components/FreelanceWork";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Partners2 } from "../../components/Partners";
import { SocialLinks5 } from "../../components/SocialLinks";

import { ReactComponent as CheckMark } from "../../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../../lib/icons/Curve.svg";

import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ServImg1 from "../../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../../assets/images/resources/servDetailImg2-1-retreat.jpg";
import ServImg2_2 from "../../assets/images/resources/servDetailImg2-2.jpg";

const PersonalCoaching = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Coaching Program - Deconnect. Reconnect. Retreat.
        </title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Deconnect. Reconnect. Retreat."
        hometitle="Home"
        homeURL="/"
        currentPage="Deconnect. Reconnect. Retreat."
      ></PageTitle>
      <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                Why you are here…
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              You need a break. You want to find yourself again, breathe, and stand still. Life feels heavy, your thoughts are scattered, and the daily grind is overwhelming.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Another day in traffic, endless emails, and no time to think. You crave me-time in a relaxed, inspiring setting.
              </p>
            </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-5 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  You want to…
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  You seek a partner in crime, someone with the right know-how, who’s walked this path—not just for themselves but alongside others. It’s not that you do not know what need to happen – it’s more find that boost, that power, that energy to finally do it.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="bullet-list">
                    <li>
                      Focus on YOU 
                    </li>
                    <li>
                      Reconnect with what matters 
                    </li>
                    <li>
                      Find yourself 
                    </li>
                    <li>
                      Connect with nature
                    </li>

                  </ul>
                </p>
              </div>
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full">
                <img className="w-full" src={ServImg2_1} alt=""></img>
                {/* Service Detail Image */}
              </div>
              
            </div>
            <div className="servDetailDesc flex flex-col gap-6 w-full">
            <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
            Deconnect. Reconnect.
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                You seek a moment of silence and renewal in this fast-paced world. You’re ready for an 
adventure, love nature, and want to take time for yourself. You need some guidance during 
these me-days to clear your mind, feel passion, and refocus.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Tasting the freedom and glamping in a DIY truck... why not!
                </p>
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                What’s part of the program?
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Three days to disconnect and reconnect. Give yourself the gift of stopping the spinning world 
                and focusing on you.
                </p>
                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Day 0
                </h5>

                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Kick off Connect to prepare 
                </p>

                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Day 1
                </h5>

                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">

                <ul className="bullet-list">
                    <li>
                Welcome & Location exploration 
                    </li>
                    <li>
Workshop: Back to Me and My dreams  
                    </li>
                    <li>
Reflection time 
                    </li>
                    <li>
Sunset stretching
                    </li>
                  </ul>

                </p>

                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Day 2
                </h5>

                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">

                 

                <ul className="bullet-list">
                <li>
                Sunrise walk (optional)
                    </li>
                    <li>
Gentle wake up movement and breathing 
                    </li>
                    <li>
Workshop: Exploring Obstacles and Opportunities 
                    </li>
                    <li>
Reflection time 
                    </li>
                    <li>
Sunset stretching 
                    </li>
                  </ul>

                </p>

                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Day 3
                </h5>

                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                <ul className="bullet-list">
                    <li>
                Sunrise walk (optional) 
                    </li>
                    <li>
Gentle wake up movement and breathing 
                    </li>
                    <li>
Workshop: My Optimistic Road 
                    </li>
                    <li>
Intention setting
                    </li>
                  </ul>

                </p>

                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                Our offer to you
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                You'll return refreshed, full of energy, clear thoughts, and a smile. We provide 3 days of guidance 
                during the Disconnect. Reconnect. Retreat.
                </p>
                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Includes
                </h5>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                <ul className="bullet-list">
                    <li>
                    3 Workshops 
                    </li>
                    <li>
                    Gentle Wake Up movement and breathing session
                    </li>
                    <li>
                    Sunset stretching sessions 
                    </li>
                    <li>
                    Glamping in Blocky the truck (you inhabit the box including bathroom, Coach Ev inhabits 
                      the cabin) 
                    </li>
                    <li>
                    Healthy meals 
                    </li>
                    <li>
                    Price: 1850 euros private (excludes own transport) or register as a duo for 2100 euros 
(includes meals for two)
                    </li>
                  </ul>

                </p>
              </div>
            
            {/* Service Detail */}
          </div>
        </div>
        
        {/* Page Wrap */}
      </section>
      

      <ContactUs></ContactUs>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

const ProgramSummary = () => (
  <div className="servDetailTop gap-[1.875rem] lg:flex items-end relative w-full mb-[3.125rem] sm:mb-[4rem] md:mb-[5.3125rem]">
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full lg:max-w-[48.125rem]">
                <img className="w-full" src={ServImg1} alt=""></img>
                {/* Service Detail Image */}
              </div>
              <div className="servDetailInfoWrap flex flex-col w-full lg:w-[initial] gap-[2.8125rem]">
                <div className="servDetailInfo p-8 lg:px-16 lg:py-12 xl:px-20 xl:py-14 relative flex gap-[1.5625rem] mt-5 lg:ml-[-10.625rem] lg:min-w-[35.625rem] z-[1]">
                  <div
                    className="before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[1.875rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] inset-0 w-full bg-no-repeat bg-center bg-cover z-[-1] bg-gray"
                    style={{ backgroundImage: `url(${PatternImg2})` }}
                  ></div>
                  <UiCreativeDesignIcon className="h-[5.3125rem] w-[5.3125rem] fill-accent"></UiCreativeDesignIcon>
                  <div className="flex flex-col gap-2">
                    <span className="text-accent font-NunitoSans text-[1rem] md:text-[1.125rem] font-semibold">
                      What do you get in the
                    </span>
                    <h2 className="text-accent2 font-Poppins font-bold leading-[1.3] text-[1.875rem] lg:text-[2rem] xl:text-[2.5rem]">
                      3-month program
                    </h2>
                  </div>
                </div>
                <ul className="flex flex-col gap-5">
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>3 months of 1-1 life-changing guidance.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>2 transformative workshops.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>6 bi-weekly coaching sessions.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Personal growth methodologies.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Tips and recommendations for podcasts, books, and articles.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>A boost in courage to step out of your comfort zone.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Achieve more than you ever dreamed.</span>
                  </li>
                </ul>
                {/* Service Detail Information Wrap */}
              </div>
              {/* Service Detail Top */}
            </div>
);

export default PersonalCoaching;
