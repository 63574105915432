import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles3 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { FreelanceWork3 } from "../../components/FreelanceWork";
import { Header2 as Header } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Partners2 } from "../../components/Partners";
import { SocialLinks5 } from "../../components/SocialLinks";

import { ReactComponent as CheckMark } from "../../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../../lib/icons/Curve.svg";

import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ServImg1 from "../../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../../assets/images/resources/servDetailImg2-1-business.jpg";
import ServImg2_2 from "../../assets/images/resources/servDetailImg2-2.jpg";

const PersonalCoaching = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Coaching Program - Dream. Dare. Do.
        </title>
      </Helmet>
      <Header></Header>
      <PageTitle
        title="Game-changing Business Workshops"
        hometitle="Home"
        homeURL="/"
        currentPage="Business Workshop"
      ></PageTitle>
      <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                Why you are here…
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                You want to challenge your business status quo, look for something different than usual.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              Hearing about this international athlete (ex-rugby) with a corporate change management background, living and working in a DIY truck—this is different.  
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              Evelien offers a fresh perspective on your business life, helping you rethink, find common  purpose, and inspire your team.
              </p>
            </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-5 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  You look for…
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                A hands-on workshop or training where you, your team, or your company can actively participate. You need someone to shift your perspective on why you do what you do, how to connect as a team, and what can be done differently. 
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Key elements include:
                <ul>
                  <li>Purpose/why</li>
                  <li>Daring and knowing/how</li>
                  <li>Priorities and a plan/what</li>
                </ul>
                </p>
              </div>
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full">
                <img className="w-full" src={ServImg2_1} alt=""></img>
                {/* Service Detail Image */}
              </div>
              
            </div>
            <div className="servDetailDesc flex flex-col gap-6 w-full">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                Our offer to you
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  Available both online or face-to-face, optionally with Evelien’s truck (inside for small groups, or 
                  outside using the tailgate as a stage).
                </p>
                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Workshops/Training
                </h5>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="bullet-list">
                    <li>Facilitation and co-creation</li>
                    <li>Own your Why (Purpose and Ownership) </li>
                    <li>Goalsetting and Priorities </li>
                    <li>Power of Strong Speech in Business (Storytelling) </li>
                    <li>Custom topics (Let’s talk)</li>
                  </ul>
                </p>

                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                Key note
                </h5>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="bullet-list">
                    <li>
                      Re. Purpose/Owning your why: How holding onto nothing means everything 
                    </li>
                    <li>
                      Re. Growth Culture: Bouncing back up: Dare to fail, adjust, change. 
                    </li>
                    <li>
                    Custom topics (Let’s talk). 
                    </li>
                  </ul>
                </p>


                <h5 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.2rem]">
                  Coaching
                </h5>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  Need a partner to guide you? Ask about our coaching journeys for you or your team in Change 
                  Management, Leadership, Storytelling, Career Path/Switch, and Mindset.
                </p>


                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                What’s next?
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  For an open conversation, share your request with Evelien on email <a href="mailto:ev@optimiro.com">ev@optimiro.com</a> or schedule your connection call here. 
                </p>
              </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
            <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full">
                <img className="w-full" src={ServImg2_2} alt=""></img>
                {/* Service Detail Image */}
              </div>
              <div className="servDetailDesc flex flex-col gap-5 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Dream. Dare. Do. Program, what’s it all about?
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Evelien is your dedicated 1-1 coach, who listens to you, dives into what matters, and walks the road alongside you. Challenge the status quo and change your (business) life. After your intake (chemistry call), it’s time to kick off the program. Together, you’ll go through three stages: Dream, Dare, Do.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                You seek a guide to support you through ups and downs, help you revive your passion, live authentically, and turn your dreams into reality. You know the choice starts with yourself. If not now, when?
                </p>
              </div>
            </div>
            {/* Service Detail */}
          </div>
        </div>
        
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

const ProgramSummary = () => (
  <div className="servDetailTop gap-[1.875rem] lg:flex items-end relative w-full mb-[3.125rem] sm:mb-[4rem] md:mb-[5.3125rem]">
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full lg:max-w-[48.125rem]">
                <img className="w-full" src={ServImg1} alt=""></img>
                {/* Service Detail Image */}
              </div>
              <div className="servDetailInfoWrap flex flex-col w-full lg:w-[initial] gap-[2.8125rem]">
                <div className="servDetailInfo p-8 lg:px-16 lg:py-12 xl:px-20 xl:py-14 relative flex gap-[1.5625rem] mt-5 lg:ml-[-10.625rem] lg:min-w-[35.625rem] z-[1]">
                  <div
                    className="before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[1.875rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] inset-0 w-full bg-no-repeat bg-center bg-cover z-[-1] bg-gray"
                    style={{ backgroundImage: `url(${PatternImg2})` }}
                  ></div>
                  <UiCreativeDesignIcon className="h-[5.3125rem] w-[5.3125rem] fill-accent"></UiCreativeDesignIcon>
                  <div className="flex flex-col gap-2">
                    <span className="text-accent font-NunitoSans text-[1rem] md:text-[1.125rem] font-semibold">
                      What do you get in the
                    </span>
                    <h2 className="text-accent2 font-Poppins font-bold leading-[1.3] text-[1.875rem] lg:text-[2rem] xl:text-[2.5rem]">
                      3-month program
                    </h2>
                  </div>
                </div>
                <ul className="flex flex-col gap-5">
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>3 months of 1-1 life-changing guidance.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>2 transformative workshops.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>6 bi-weekly coaching sessions.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Personal growth methodologies.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Tips and recommendations for podcasts, books, and articles.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>A boost in courage to step out of your comfort zone.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Achieve more than you ever dreamed.</span>
                  </li>
                </ul>
                {/* Service Detail Information Wrap */}
              </div>
              {/* Service Detail Top */}
            </div>
);

export default PersonalCoaching;
