import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../components/BottomBar";
import { ContactInfoProfiles3 } from "../components/ContactInfoProfiles";
import { ContactUs } from "../components/ContactUs";
import { FreelanceWork3 } from "../components/FreelanceWork";
import { Header2 } from "../components/Header";
import { PageTitle } from "../components/PageTitle";
import { Partners2 } from "../components/Partners";
import { SocialLinks5 } from "../components/SocialLinks";

import { ReactComponent as CheckMark } from "../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../lib/icons/Curve.svg";

import PatternImg2 from "../assets/images/patternImg2.jpg";
import ServImg1 from "../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../assets/images/resources/servDetailImg2-1-mystory.jpg";
import ServImg2_2 from "../assets/images/resources/servDetailImg2-mystory.jpg";

const PersonalCoaching = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Here's my story
        </title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Here's my story"
        hometitle="Home"
        homeURL="/"
        currentPage="My Story"
      ></PageTitle>
      <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                How did I get here
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                I (Evelien Rosier a.k.a Energetic Ev) said farewell to my 17-year long international rugby career, left my wonderful job as a change manager, and stepped outside my cosy home to realize my childhood dream:
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              "Backpack around the world for one year."
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              It required all my courage, my money, and my perseverance to push through, take the step, jump, go for it.
              </p>
            </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-5 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                2020 was gonna be my year.
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Think again. It was the worst year to travel around the world. The 2nd country I entered - with my backpack and a big smile - I got stuck because of the Pandemic. There I was, at the other side of the world in Australia, and my big dream broke into a million of pieces.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Initially I couldn’t believe it, “why now?!”, I cried, felt very lost.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                But soon I realized I had two choices: either stay in that feeling of “why” or shift to “what can I do?”.

I took this perfect opportunity to stand still & rethink my entire future.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="bullet-list">
                    <li>What makes me happy?</li>
                    <li>What do I love?</li>
                    <li>What do I want to live for?</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full">
                <img className="w-full" src={ServImg2_1} alt=""></img>
                {/* Service Detail Image */}
              </div>
              
            </div>
            
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
            <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full">
                <img className="w-full" src={ServImg2_2} alt=""></img>
                {/* Service Detail Image */}
              </div>
              <div className="servDetailDesc flex flex-col gap-5 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                    3 things became very clear...
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                "I'm the happiest person when..."<br />
                1 - I feel free, which means I feel like I can go anywhere, anytime.<br />
                2 - I help someone finding the guts, the power, the courage to chase his/her dream, because anything is possible. <br />
                3 - I'm combining both of the above.<br />
                </p>
                <h5>That was it, that was the answer. </h5>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                
After thinking it through and investing 15.000 euro in upskilling myself, optimiro was born.
Why? To "optimise my road"... And yours.

                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                And what now? Besides growing my business, I'm currently turning a truck into my mobile coaching office and house. Looking forward guiding you from my truck on doing more of what you love, turn your ideas into a future-proof  vision, and power up your mindset.

                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                The sweet spot of optimiro lies in combining the best of both the corporate and the elite sports worlds into strong coaching programs.

                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                How does that work? 
Let us show you in a call.

                </p>
              </div>
            </div>
            {/* Service Detail */}
          </div>
        </div>
        
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

const ProgramSummary = () => (
  <div className="servDetailTop gap-[1.875rem] lg:flex items-end relative w-full mb-[3.125rem] sm:mb-[4rem] md:mb-[5.3125rem]">
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full lg:max-w-[48.125rem]">
                <img className="w-full" src={ServImg1} alt=""></img>
                {/* Service Detail Image */}
              </div>
              <div className="servDetailInfoWrap flex flex-col w-full lg:w-[initial] gap-[2.8125rem]">
                <div className="servDetailInfo p-8 lg:px-16 lg:py-12 xl:px-20 xl:py-14 relative flex gap-[1.5625rem] mt-5 lg:ml-[-10.625rem] lg:min-w-[35.625rem] z-[1]">
                  <div
                    className="before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[1.875rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] inset-0 w-full bg-no-repeat bg-center bg-cover z-[-1] bg-gray"
                    style={{ backgroundImage: `url(${PatternImg2})` }}
                  ></div>
                  <UiCreativeDesignIcon className="h-[5.3125rem] w-[5.3125rem] fill-accent"></UiCreativeDesignIcon>
                  <div className="flex flex-col gap-2">
                    <span className="text-accent font-NunitoSans text-[1rem] md:text-[1.125rem] font-semibold">
                      What do you get in the
                    </span>
                    <h2 className="text-accent2 font-Poppins font-bold leading-[1.3] text-[1.875rem] lg:text-[2rem] xl:text-[2.5rem]">
                      3-month program
                    </h2>
                  </div>
                </div>
                <ul className="flex flex-col gap-5">
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>3 months of 1-1 life-changing guidance.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>2 transformative workshops.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>6 bi-weekly coaching sessions.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Personal growth methodologies.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Tips and recommendations for podcasts, books, and articles.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>A boost in courage to step out of your comfort zone.</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <CheckMark className="fill-accent h-[1rem] md:h-[1.125rem]"></CheckMark>
                    <span>Achieve more than you ever dreamed.</span>
                  </li>
                </ul>
                {/* Service Detail Information Wrap */}
              </div>
              {/* Service Detail Top */}
            </div>
);

export default PersonalCoaching;
