import { BrowserRouter, Routes, Route } from "react-router-dom";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";

import { HomePage } from "../pages/HomePages";
import PortfolioPage from "../pages/PortfolioPages/PortfolioDetail";
import PersonalCoaching from "../pages/ServicePages/PersonalCoaching";
import BusinessCoaching from "../pages/ServicePages/BusinessCoaching";
import RetreatCoaching from "../pages/ServicePages/RetreatCoaching";
import Mystory from "../pages/MyStory";

const RouterLinks = () => {
  return (
    <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<HomePage></HomePage>} />

            <Route path="/home2" element={<HomePage></HomePage>} />
            <Route path="/services/personal-coaching" element={<PersonalCoaching></PersonalCoaching>} />
            <Route path="/services/business-coaching" element={<BusinessCoaching></BusinessCoaching>} />
            <Route path="/services/retreat-coaching" element={<RetreatCoaching></RetreatCoaching>} />
            <Route path="/my-story" element={<Mystory></Mystory>} />

            {/* <Route path="/home2" element={<HomePage2></HomePage2>} />

            <Route path="/home2" element={<HomePage2></HomePage2>} />
            <Route path="/blog" element={<BlogPage></BlogPage>} />
            <Route path="/blog/:Id" element={<BlogDetail></BlogDetail>} />
            <Route path="/blog2" element={<BlogPage2></BlogPage2>} />
            <Route path="/plans" element={<PlansPage></PlansPage>} />
            <Route path="/service" element={<ServicePage></ServicePage>} />
            <Route path="/service/:Id" element={<ServiceDetail></ServiceDetail>} />
            <Route path="/portfolio" element={<PortfolioPage></PortfolioPage>} />
            <Route path="/portfolio/:Id" element={<PortfolioDetail></PortfolioDetail>} />
            <Route path="/portfolio2" element={<PortfolioPage2></PortfolioPage2>} />
            <Route path="/portfolio3" element={<PortfolioPage3></PortfolioPage3>} />
            <Route path="/contact" element={<ContactPage></ContactPage>} />
            <Route path="/404" element={<ErrorPage></ErrorPage>} />
            <Route path="*" element={<ErrorPage></ErrorPage>} />*/}
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
  );
};

export default RouterLinks;
